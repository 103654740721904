.scroll-menu-arrow--disabled {
    visibility: hidden !important;
}

.menu-wrapper--inner {
    display: flex;
    // margin-left: -1rem;
}

.menu-item-wrapper:not(:last-child) {
    &>div {
        min-width: 54px;
    }
}

.scroll-menu-arrow {
    /*padding: 1rem;*/
}

.menu-item-wrapper {
    &:focus {
        outline: none;
    }
}

.owl-nav {
    div {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background: #2525258a;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        &.owl-prev {
            left: -10px;
        }
        &.owl-next {
            right: - 10px;
        }
    }
}

.betting-owl {
    margin: 0.5rem 1.1rem;
    .owl-prev {
        left: -28px !important;
    }
    .owl-next {
        right: -28px !important;
    }
}

.pool-owl {
    margin: 0 2.2rem;
    .owl-prev {
        left: -32px !important;
    }
    .owl-next {
        right: -32px !important;
    }
    .brand-icon {
        margin: 0 auto;
    }
}