// Intend to only @import other files for scss compilation
@import "1-helpers/_variables";
@import "1-helpers/_mixins";
// plugins
@import "2-plugins/scrollMenu";
@import "2-plugins/reactDatepicker";
@import "2-plugins/_multiSelect";
@import "2-plugins/_keyboard";
@import "2-plugins/_sweetAlert";
// basics
@import "3-basics/_typography";
@import "3-basics/_base";
@import "3-basics/_sidebar";
@import "3-basics/_buttons";
@import "3-basics/_scrollbar";
// module level import
@import "4-modules/_home";
@import "4-modules/_betting";
@import "4-modules/_result";
// mobile reponsive
@import "_responsive";

.weborder-info-wrapper {

    color:  #000;
    font-size: .8rem;
    margin-bottom: 1rem !important;

    .panel {
        margin-bottom: 0;
    }

    .panel-body {
        padding: 8px 15px;
    }

    .bet-agent-wrapper {
        .form-group {
            label {
                margin-bottom: 0;
            }
        }
    }
}

.pool-schedule-wrapper {
    .availablePoolList {
        padding: 0.5rem;
        align-items: center;
        justify-content: unset;
    }

    .availablePool {
        margin-bottom: 0;
        border-radius: 10px;
        overflow: hidden;

        & > div {
            height: 2.5rem;
        }
    }

    .poolCutOffImage {
        width: 25px;
        height: 25px;
    }

    .availablePoolDate {
        align-items: center;
        display: flex;
        padding-left: 1rem;
        padding-right: 2rem;
        font-size: .8rem;        
        width: 180px !important;

        &:not(:last-child) {
            border-bottom: 1px solid #ccc;
        }
    }
}

.pool-cutoff-wrapper {
    font-size: .8rem;
    figure {
        margin-bottom: 0;
        text-align: center;

        .poolCutOffImage {
            margin-left: 0;
            width: 25px;
            height: 25px;
        }
    }
}

.flex-1 {
    flex: 1
}

.weborder-right-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 0.6rem;
}

.table-bet-total {
    tbody {
        tr:first-child {

            td,
            th {
                border-top: none;
            }
        }

        th,
        td {
            padding: 2px;
            border-color: #f1f1f1
        }
    }

    tfoot {

        th,
        td {
            padding: 2px;
            border-color: #f1f1f1
        }
    }
}

.table {
    .fa-sort {
        font-size: 0.7rem !important;
    }
}

.table-brand {
    thead {
        tr {
            background: #69f;

            th {
                color: black !important;
                border-color: black !important;
            }
        }
    }

    tbody {
        tr:hover {
            cursor: pointer;

            td {
                background-color: #C0C0C0;
            }
        }

        td {
            vertical-align: top;
            border-color: black !important;
        }
    }

    tfoot {
        td,
        th {
            background: #C0C0C0;
            color: black;
            font-weight: bold;
            border-color: black !important;

            &.profit-col {
                background: LightBlue !important;
            }

            &.earned-col {
                background: #c3e4f2 !important;
            }
        }
    }
}

@mixin brand-table {
    overflow: hidden;
    background: #fff;
}

.table-brand-striped {
    @include brand-table;
    font-weight: bolder;
    border-color: black !important;

    tr:hover {
        cursor: pointer;

        &:nth-child(4n),
        &:nth-child(4n-1) {
            td {
                background-color: #C0C0C0;
            }
        }

        &:nth-child(4n-2),
        &:nth-child(4n-3) {
            td {
                background-color: #C0C0C0;
            }
        }
    }

    thead {
        tr {
            background: #69f;
            border-color: black !important;

            th {
                background: #69f;
                border-color: black !important;
            }
        }
    }

    tbody {
        td {
            border-color: black !important;
        }

        tr:nth-child(4n),
        tr:nth-child(4n-1) {
            td[rowspan] {
                background-color: #69f;
                vertical-align: middle;
                border-color: black !important;
            }
        }

        tr:nth-child(4n-2),
        tr:nth-child(4n-3) {
            td[rowspan] {
                background-color: #E2E7F8;
                vertical-align: middle;
                border-color: black !important;
            }
        }
    }

    tfoot {
        td,
        th {
            background: #C0C0C0;
            color: black;
            font-weight: bold;
            border-color: black !important;
        }
    }
}

.table.table-weborder {
    @include brand-table;

    font-size: .8rem;
    tbody {
        td {
            padding: 0px 2px !important;
            max-width: 120px !important;

            input {
                height: unset;
                padding: 5px 0;

                &:focus {
                    box-shadow: none;
                }
            }
        }

        td:last-child {
            padding: 0px 7px !important
        }
    }
}

.css-1fdsijx-ValueContainer {
    padding: 1px 1px !important;
}

.css-t3ipsp-control {
    height: 8px !important;
}

.btn-primary-outline {
    border-color: darkgoldenrod;
    background-image: linear-gradient(180deg, #efbc62, #f2d179);
}



