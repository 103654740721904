$btn-size: 2.8rem;
$btn-size-sm: 1.8rem;
$border-radius: 0.5;

@mixin expand-anim ($color, $background-color: $secondary-white) {
    &::after {
        @include box-shadow(inset 0 0 0 2px darken($background-color, 5%));
    }

    &:hover::after {
        @include box-shadow(inset 0 0 0 2px $color);
    }

    &::before {
        background: $color;
        box-shadow: inset 0 0 0 30px darken($background-color, 5%);
    }

    &:hover::before {
        box-shadow: inset 0 0 0 1px darken($background-color, 5%);
    }
}

@mixin btn-color ($color, $text-color) {
    border-color: $color;
    background-color: $color;
    color: $text-color;
    transition: all 0.3s;

    &:hover {
        background-color: darken($color, 6%);
        transition: all 0.3s;
    }
}

.btn-round {
    cursor: pointer;
    position: relative;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    width: $btn-size;
    height: $btn-size;
    border-radius: $btn-size * $border-radius;
    overflow: hidden;

    &::before,
    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        text-align: center;
        vertical-align: middle;
        transition: all 0.25s ease;
        border-radius: $btn-size * $border-radius;
    }

    i {
        position: relative;
        color: darken($gray, 30%);
        font-size: 1rem;
        margin-top: $btn-size / 3;
        transition: all 0.25s ease;
    }

    &:hover i {
        color: $white;
    }

    &.btn-sm {
        width: $btn-size-sm;
        height: $btn-size-sm;
        border-radius: $btn-size-sm * $border-radius;
        padding: 0;

        i {
            font-size: 0.8rem;
            margin-top: 0;
        }
    }

    &.expand-theme {
        @include expand-anim($theme);
    }

    &.expand-green {
        @include expand-anim($green);
    }

    &.expand-red {
        @include expand-anim($red);
    }
}

.btn {
    &.btn-green {
        @include btn-color($green, $white);
    }

    &.btn-red {
        @include btn-color($red, $white);
    }

    &.btn-themed {
        @include btn-color($theme, $white);
    }
}

.btn-seperator {
    display: inline;
    margin-left: 15px;
    margin-right: 15px;
    border-right: 1px solid $light-gray;
    padding: 4px 0px 8px 0px;
}

.page-back-btn {
    margin-left: -1rem;
}

.btn-exit {
    height: 34px;
    width: 34px;
    border-radius: 13px !important;
    margin-right: .5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.4rem;
}

.theme-btn-2 {
    text-decoration: none;

    &:hover {
        color: #fff;
    }
}

.btn-min-width {
    min-width: 100px
}

.btn-top-share {
    padding: 3px 10px !important;
}

.btn-share {
    padding: 0.3rem 0.6rem;
    font-family: 'SFProDisplay-Regular';
    border-radius: 8px;
    font-size: 90%;
    text-decoration: none !important;
    display: inline-flex;
    align-items: center;

    img {
        margin-left: .2rem;
        width: .8rem;
        height: 1rem;
    }
}

.btn-share-list {
    text-align: center;
    margin-bottom: 1rem;

    .btn-share:not(:last-child) {
        margin-right: .5rem;
    }
}

.btn-telegram {
    background: linear-gradient( 339deg, #6CC1E3, #3E4392);
    color: #fff;

    &:hover {
        color: #fff !important;
        box-shadow: none;
    }
}

.btn-share-wa {
    background: linear-gradient(141deg, #0CC155, #0DCA4B, #5CE50B, #6CF408);
    color: #fff;

    &:hover {
        color: #fff !important;
        box-shadow: none;
    }
}
