@media (min-width: 320px) and (max-width: 575px) {
    body {
        font-size: 14px;
        .gold-jumbotron {
            padding: 5px;
        }
    }
    .header {
        .navbar-brand {
            padding: 0px;
            margin-right: 0px;
        }
    }
    #homepage {
        #homepage-swiper {
            .swiper-content-wrapper {
                // padding: 17px;
            }
            .swiper-item-text {
                padding-top: 5px;
                font-size: 12px;
                width: fit-content;
                margin: auto;
            }
            .swiper-item-icon {
                height: fit-content !important;
            }
            .swiper-slide img {
                width: 40px;
                height: 40px;
            }
        }
    }
    #bettingHistory {
        .nav.nav-tabs+.tab-content {
            padding-left: 0px;
            padding-right: 0px;
        }
        .clock-icon img {
            width: 30px;
            height: 30px;
        }
        input {
            width: 100%;
            &:-moz-placeholder,
            &:-ms-input-placeholder,
            &::-moz-placeholder,
            &::-webkit-input-placeholder {
                /* Chrome/Opera/Safari */
                font-size: 12px;
            }
        }
        span {
            white-space: nowrap;
        }
        .general-text {
            line-height: 1;
        }
    }
    #bettingPage {
        label {
            white-space: nowrap;
        }
        .betting-jumbo {
            .betting-num-container {
                .item-num {
                    padding: 10px 20px 10px 20px;
                    select {
                        height: 30px;
                    }
                }
                .selectnum-item {
                    padding: 3px !important;
                    background: $white;
                    border-radius: 13px;
                }
            }
        }
    }
    #bettingSummary {
        .icon-wrapper img {
            width: 35px;
            height: 35px;
        }
    }
    #resultPage {
        .gold-jumbotron {
            .clock-icon img {
                width: 30px;
                height: 30px;
            }
        }
        .result-container {
            table {
                img {
                    width: 40px;
                    height: 40px;
                }
                thead th,
                tbody td {
                    font-size: .9rem;
                    padding: 2px;
                }
            }
        }
        .swiper-brand-icon-wrapper {
            img.brand-icon {
                width: 40px;
                height: 40px;
            }
        }
    }
    #strikeHistory {
        .history-item {
            img {
                width: 35px !important;
                height: 35px;
            }
            h3 {
                font-size: 18px;
            }
        }
    }
}

@media (min-width: 576px) and (max-width: 767px) {
    body {
        .gold-jumbotron {
            padding: 10px;
            .clock-icon img {
                width: 45px;
            }
        }
        .page-header-fixed {
            padding-top: 0px;
        }
        .page-without-sidebar .header .navbar-brand {
            justify-content: left;
        }
    }
    #bettingPage .betting-jumbo.betting-brand img.brand-icon {
        width: 70px;
    }
    #homepage {
        #homepage-swiper {
            .swiper-content-wrapper {
                padding: 10px;
                img {
                    width: 75px;
                    height: 75px;
                }
            }
        }
        .place-order-item {
            .place-order-misc-item {
                padding: 10px;
            }
            img {
                height: 125px;
                object-fit: cover;
            }
        }
    }
    .betting-jumbo {
        .betting-num-container {
            .selectnum-item {
                padding: 7px !important;
                background: $white;
                border-radius: 13px;
            }
        }
    }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991px) {
    .gold-jumbotron {
        padding: 15px 20px 15px 20px;
        .clock-icon img {
            width: 50px;
        }
    }
    .page-without-sidebar .header .navbar-brand {
        justify-content: left;
    }
    #homepage {
        #homepage-swiper {
            .swiper-content-wrapper {
                padding: 15px;
            }
        }
        .place-order-item .place-order-misc-item {
            padding: 20px;
        }
    }
    .betting-jumbo {
        .betting-num-container {
            .selectnum-item {
                padding: 7px !important;
                background: $white;
                border-radius: 13px;
            }
        }
    }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199px) {
    .gold-jumbotron {
        padding: 15px 20px 15px 20px;
    }
    .form-footer .set-footer-layout {
        padding-left: 100px;
        padding-right: 100px;
    }
    #homepage-swiper {
        .swiper-content-wrapper {
            padding: 20px;
        }
    }
    .betting-jumbo {
        .betting-num-container {
            .selectnum-item {
                padding: 7px !important;
                background: $white;
                border-radius: 13px;
            }
        }
    }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
    .gold-jumbotron {
        padding: 15px 20px 15px 20px;
    }
    .form-footer .set-footer-layout {
        padding-left: 150px;
        padding-right: 150px;
    }
    #homepage-swiper {
        .swiper-content-wrapper {
            padding: 30px;
        }
        & .swiper-item-text {
            padding-top: 20px;
        }
    }
    .betting-jumbo {
        .betting-num-container {
            .selectnum-item {
                padding: 7px !important;
                background: $white;
                border-radius: 13px;
            }
        }
    }
}