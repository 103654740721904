﻿.react-datepicker {
    .react-datepicker__day {
        color: #191a20 !important;
    }
}

.react-datepicker-input {
    &:focus {
        outline: none;
    }
}

.date-selection-wrapper {
    display: block;
    width: 100%;
    margin-bottom: 1rem;

    .selection-item {
        display: inline-block;
        padding: .2rem;
        border: 1px solid #2a2a2a;
        border-radius: .5rem;
        font-size: 0.7rem;
        box-shadow: 2px 2px 4px #0000008a;

        &:not(:last-child) {
            margin: 0 .2rem .2rem 0;
        }

        &.active {
            background: #f2cd75;
            color: #161618;
        }
    }
}