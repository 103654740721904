$box-shadow: 1px 0px 5px 0px rgba($gray, 1);

html,
body {
    // width: 100%;
    // height: 100%;
    font-family: $font;
    font-size: $body-font-size;
    background-color: $body-bg-color;
    color: $body-font-color;

    &::-webkit-scrollbar {
        width: 13px;
    }
    /* Track */
    &::-webkit-scrollbar-track {
        background: $gray;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: $theme;
    }
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: darken($theme, 5%);
    }
}

.header {
    @include box-shadow(0px 0px 5px 0px rgba($gray, 0.75));
}

.breadcrumb .breadcrumb-item {
    cursor: default;
}

.page-header {
    display: flex;
    align-items: center;
    color: $black;
}

.widget {
    &.widget-stats {
        color: $white;

        .stats-title,
        .stats-desc {
            color: inherit;
        }

        .stats-progress {
            background: $black;

            .progress-bar {
                background: $white;
            }
        }
    }
}

.card {
    @include box-shadow(1px 1px 5px 0px rgba($black, 0.35));

    &.card-theme {
        @include box-shadow(1px 1px 5px 0px rgba($theme, 0.35));
        background-color: $theme;
        color: $white;
        border: none;
    }
}

.referral-credit-amt {
    font-size: 3rem;
}

.referral-credit-smallnote {
    font-style: italic;
}

.content.has-footer {
    padding-bottom: 75px;
}

.text-bold {
    font-family: $font-bold;
}

.text-theme {
    color: $theme;
}

.text-pending {
    color: $orange;
}

.text-blue {
    color: $blue;
}

.text-pink {
    color: $pink;
}

.text-status {
    font-family: $font-bold;

    &.active {
        color: $green;
    }

    &.inactive {
        color: $red;
    }

    &.pending {
        color: $orange;
    }
}

form {
    label {
        font-family: $font-bold;
    }

    .radio label {
        font-family: $font;
    }

    .form-control {
        &:not(.form-control-plaintext):focus {
            @include box-shadow(0px 0px 0px 0.1rem rgba($theme, .25));
            border-color: $theme;
        }
        // &.has-error {
        //     @include box-shadow(0px 0px 0px 0.1rem rgba($red, .25));
        //     border-color: $red;
        // }
    }

    .form-control-lg {
        font-size: 1rem;
    }

    .form-control-plaintext {
        cursor: default;
    }

    .form-control-plaintext:focus {
        outline: none;
        box-shadow: none;
        border: none;
    }

    .input-error {
        color: $red;
        text-align: left;
        height: 0.9rem;
        font-size: 0.9rem;
        font-style: italic;
    }
}

.form-footer {
    display: flex;
    position: fixed;
    bottom: 0;
    left: 0;
    width: calc(100% - 230px);
    margin-left: 230px;
    padding: 10px 15px;
    justify-content: center;
    background-color: darken($white, 2%);
    @include box-shadow(0px 0px 5px 0px rgba($gray, 0.75));
}

.tabs {
    margin-bottom: 15px;

    .tab-item {
        height: 60px;
        text-align: center;
        padding: 15px 20px;
        cursor: pointer;
        background-color: $white;
        font-size: 1rem;
        border-bottom: 2px solid $white;
        transition: all 0.3s;

        &.active {
            border-bottom: 2px solid $theme;
        }

        &:hover {
            font-size: 1.1rem;
            background-color: $secondary-white;
            transition: all 0.3s;
        }
    }

    div {
        &:not(:first-child) {
            padding-left: 0px;
        }

        &:not(:last-child) {
            border-right: 1px solid $light-gray;
            padding-right: 0px;
        }
    }
}

.btn-float-right {
    float: right;
    margin-top: -5px;
}

.radio.radio-css {
    user-select: none;
    /* supported by Chrome and Opera */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    label {
        cursor: pointer;
        padding-left: 35px;
        font-size: 1.05rem;
        line-height: 23px;
        margin-right: 10px;
    }

    label:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 25px;
        height: 25px;
        background: $light-gray;
        border-radius: 25px;
        transition: all 0.3s;
    }

    input:checked {
        + label:before {
            background: $theme;
            border-color: $theme;
            transition: all 0.3s;
        }

        + label:after {
            content: '';
            position: absolute;
            top: 7px;
            left: 7px;
            width: 11px;
            height: 11px;
            background: $white;
            border-radius: 6px;
            transition: all 0.3s;
        }
    }
}

.picker-container {
    width: 245px;
    height: 245px;
    overflow: auto;
    display: flex;
    flex-wrap: wrap;
    margin: auto;

    .picker-item {
        height: 30px;
        margin: 5px;
        text-align: center;
        line-height: 30px;
        font-size: 13px;
        cursor: pointer;
        transition: all 0.2s;

        &:hover {
            background-color: $theme;
            color: $white;
            transition: background-color 0.2s, color 0.1s;
        }
        // background: $secondary-white;
        &.day {
            width: 30px;
            border-radius: 30px;
        }

        &.month {
            width: 110px;
            border-radius: 10px;
        }

        &.year {
            width: 69px;
            border-radius: 10px;
        }
    }

    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-track {
        background: $gray;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: $theme;
    }
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: darken($theme, 5%);
    }
}

.jumbotron {
    margin-bottom: 1rem;
}

.datefilter-wrapper {
    padding: .3rem .5rem !important;
    justify-content: space-between;

    & > div {
        display: flex;
    }

    img {
        width: 1.5rem;
        height: 2rem;
        margin-right: .5rem;
    }

    .date-wrapper {
        line-height: 1.2;

        span {
            font-family: 'SFProDisplay-Regular';
            display: block;
            color: #464440;
            font-size: 90%;
        }
    }
}

.draw-date-selection {
    display: flex;
    justify-content: space-around;
    margin-bottom: 1rem;

    .draw-date {
        display: inline-block;
        text-align: center;
    }
}

.brand-color {
    color: #f1ca71 !important;
}

.container {
    max-width: 500px !important;
}

.l-h-1 {
    line-height: 1;
}

.navbar-header {
    padding: 10px 15px;
}

.header .navbar-brand {
    padding: 0;
    height: unset;
}

.navbar-logo {
    img {
        width: 35px;
        height: 35px;
        object-fit: contain;
    }
}

.table.table-number-meaning {
    color: #fff;

    th,
    td {
        border-color: #2d2d2d
    }

    thead th {
        width: 50%;
        text-align: center;
    }

    thead td {
        border-top: none;
    }

    tbody th {
        vertical-align: middle
    }

    .number-meaning-wrapper {
        display: flex;
        align-items: center;
        flex-direction: column;
        text-align: center;

        img {
            margin-bottom: .5rem;
        }
    }
}

.empty-state-text {
    text-align: center;
    margin: 1rem;
}

.nav.nav-tabs .nav-item .nav-link {
    padding: 0.3rem .6rem !important;
}

.alert {
    font-family: 'SFProDisplay-Regular';
}

.bg-green-gradient {
    background: linear-gradient( 339deg, #0CC155, #0DCA4B, #5CE50B, #6CF408);
    color: #fff;
}

.bg-yellow-gradient {
    background: linear-gradient(to right, #cac531, #f3f9a7);
    color: #000;
}

.bg-red-gradient {
    background: linear-gradient(to right, #ff416c, #ff4b2b);
    color: #fff;
}

.modal-brand {
    .modal-content {
        background-color: #161616;
        box-shadow: 0px 5px 20px 3px #0000004d;
    }
}

.custom-control-brand {
    .custom-control-label::before {
        background-color: #0a0a0a;
        border: #454545 solid 1px;
    }

    input[type='radio']:checked ~ .custom-control-label::before,
    input[type='checkbox']:checked ~ .custom-control-label::before {
        color: #000;
        box-shadow: 0 1px 6px 0px #efbc6266;
        background-color: #cd900a;
        border-color: #cd900a !important;
    }

    .custom-control-input:focus:not(:checked) {
        & ~ .custom-control-label::before {
            border-color: lighten(#cd900a, 10%);
            box-shadow: 0 0 0 0.2rem #cd900a1a;
        }
    }
}
