﻿// 1. Colors
$black: #2D3B41;
$white: #FFF;
$secondary-white: #f2f3f4;
$light-gray: #CCC;
$gray: #a6a6a6;
$theme: #e6ac00;
$green: #00cc66;
$red: #ff5050;
$orange: #ffaa00;
$blue: #0099ff;
$pink: #ff6699;
$yellow: #e1ff00;
$primaryText: #1f2225;
// 2. Fonts
$font: "Roboto",
sans serif;
$font-bold: "RobotoBold",
sans serif;
$font-awesome: "FontAwesome5Free";
// 3. theme
$navbar-bg-color: #141414;
$body-bg-color: #141414;
$body-font-color: #EFBC62;
$body-font-size: 16px;
$font-family-sfp-bold: "SFProDisplay-Bold";
$font-family-sfp-medium: "SFProDisplay-Medium";
$font-family-sfp-regular: "SFProDisplay-Regular";
// 4. settings
$navbar-height: 40px;
$brand-border-radius: 8px;