.y-scrollbar-1 {
    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #f5f5f5;
        border-radius: 3px;
    }

    &::-webkit-scrollbar {
        width: 3px;
        background-color: #f5f5f5;
        border-radius: 3px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 3px;
        background-color: #e6ac00;
    }
}

.x-scrollbar-1 {
    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #f5f5f5;
        border-radius: 3px;
    }

    &::-webkit-scrollbar {
        height: 3px;
        background-color: #f5f5f5;
        border-radius: 3px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 3px;
        background-color: #e6ac00;
    }
}
