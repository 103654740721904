@mixin box-shadow ($value) {
    -webkit-box-shadow: $value;
    -moz-box-shadow: $value;
    box-shadow: $value;
}

@mixin h-padding($value) {
    padding-left: $value;
    padding-right: $value;
}

@mixin v-padding($value) {
    padding-top: $value;
    padding-bottom: $value;
}