$betInputHeight: 1.85rem;

#bettingPage {
    min-height: 500px;
    margin-bottom: 0;
    height: 80vh;

    .form-footer {
        position: absolute;
        bottom: 0;
    }

    .datemenu-wrapper {
        margin-bottom: 1rem;
    }

    .menu-wrapper {
        margin-top: 1rem;
    }

    .date-container {
        overflow-x: auto;
    }

    .scroll-menu-arrow {
        margin-top: 1rem;
    }

    .date-round {
        width: 50px;
        height: 50px;
    }

    .date-round,
    .menu-item-wrapper {
        font-size: 15px;
        /*width: 50px;
        height: 50px;*/
        border-radius: 50%;
        align-items: center;
        justify-content: center;
        user-select: none;
        -webkit-user-select: none;
        cursor: pointer;

        span {
            line-height: 1;
        }

        .date-day-text {
            font-family: $font-family-sfp-medium;
            color: #989898;
            margin-top: auto;
        }

        .date-text {
            font-family: $font-family-sfp-regular;
            color: #989898;
            margin-bottom: auto;
        }

        &:focus {
            outline: none;
        }
    }

    .date-round {
        &.active {
            background: linear-gradient(180deg, #efbc62, #f2d179);

            .date-day-text {
                font-family: $font-family-sfp-bold;
                color: #1c2529;
            }

            .date-text {
                font-family: $font-family-sfp-regular;
                color: #3e3e3e;
            }
        }
    }

    .betting-jumbo {
        background: transparent;
        margin-bottom: 0.5rem;
        padding: 0;

        &.betting-brand {
            img.brand-icon {
                -khtml-user-select: none;
                -o-user-select: none;
                -moz-user-select: none;
                -webkit-user-select: none;
                user-select: none;
            }
        }

        .betting-num-container {
            .item-num {
                font-family: $font-family-sfp-bold;
                border-radius: 19px;
                background: $white;
                padding: 15px 25px 15px 25px;
                box-shadow: 0px 0px 6px #0000006c;
                color: $black;
            }
        }

        .number-type-container {
            display: flex;
            margin-bottom: 0.5rem;

            .number-type {
                flex: 1;
                text-align: center;
                cursor: pointer;
                font-family: "SFProDisplay-Regular";

                @media screen and (max-width: 320px) {
                    font-size: 90%;
                }

                &.active {
                    background: linear-gradient(180deg, #efbc62, #f2d179);
                    box-shadow: #efbe6555 2px 2px 4px;
                    border-radius: 0.3rem;
                    color: #141414;
                }

                &:not(:last-of-type) {
                    margin-right: 0.5rem;
                }
            }
        }
    }

    .multiselect-date {
        --rmsc-h: $betInputHeight;
    }

    .btn-remark {
        font-family: "SFProDisplay-Regular";
        text-align: center;
        border-style: dashed;
        border: 1px dashed;
        border-radius: 6px;
        padding: 0.2rem;
        margin: 0 7px 5px 7px;
        line-height: 1.3;
        cursor: pointer;
        white-space: nowrap;
        font-size: 90%;
    }

    .remark-field {
        font-family: "SFProDisplay-Regular";
        margin: 0 7px 5px 7px;
        line-height: 1.3;
    }

    .remark-wrapper {
        display: flex;
        align-items: flex-start;
    }
}

.bg-betting-history {
    .bg-1 {
        z-index: 0;
        width: 100%;
        height: 100%;
        background: url("../../assets/img/theme/ball-itself.png");
        position: absolute;
        background-repeat: no-repeat;
        // background-position: 80% 10%;
        background-size: contain;
        background-position: center;
        background-attachment: fixed;
    }
    // .bg-2 {
    //     z-index: 0;
    //     width: 100%;
    //     height: 100%;
    //     background: url("../../assets/img/theme/Image-4.png");
    //     position: absolute;
    //     background-repeat: no-repeat;
    //     background-position: 90% 50%;
    // }
    // .bg-3 {
    //     z-index: 0;
    //     width: 100%;
    //     height: 100%;
    //     background: url("../../assets/img/theme/Image-5.png");
    //     position: absolute;
    //     background-repeat: no-repeat;
    //     background-position: 20% 30%;
    // }
}

#bettingHistory {
    .betting-history-content {
        ul {
            background: transparent;
        }

        li.nav-item {
            width: 33.33%;
            background: transparent;
            text-align: center;

            a {
                &.nav-link {
                    background: transparent;

                    &.active {
                        border-bottom: 2px solid #efbc62;
                        color: #efbc62;
                    }
                }

                &:hover {
                    color: #efbc62;
                }
            }
        }

        .tab-content {
            background: transparent;
        }

        .history-content-item {
            cursor: pointer;
            background: #141414;
            border: 1px solid;
            border-radius: 13px;
            padding: 0.6rem 0.3rem;
            box-shadow: 0 0 10px #000000;

            .bet-item-content {
                line-height: 1;
            }

            button {
                padding: 0px !important;
            }
        }
    }
}

#bettingHistoryDetails {
    .dollar-details {
        padding: 0.5rem 0.4rem;

        &:not(:last-child) {
            border-bottom: 3px dotted #efbc62;
        }

        img {
            width: 30px;
            height: 30px;
        }

        &:last-child {
            img {
                width: 25px;
                height: 25px;
                margin-left: 0.1rem;
            }
        }
    }

    .betting-details-jumbo {
        background: linear-gradient(180deg, #efbc62, #f2d179);
        border-radius: 13px;
    }

    .order-string-container {
        background: #141414;
        line-height: 1;
    }
}

#bettingSummary {
    .bet-item-wrapper {
        margin-top: 20px;

        .bet-item {
            border: 1px solid;
            border-radius: 13px;
            background: #141414;
        }

        .bet-item-trash-btn {
            position: relative;
            padding: 16px 10px;

            button {
                position: absolute;
                right: -25px;
                padding: 0.7rem 0.8rem;
            }
        }
    }
}

.bettingModal {
    .modal-content {
        border: 10px;
        background: linear-gradient(132deg, #141414, #1a1c24);

        .cancel-btn {
            border-radius: 38px;
        }

        .btn {
            min-width: 80px;
        }
    }
}

#advanceBetting {
    .paper-string-wrapper {
        color: $black;
        border-radius: 5px;
        border: 1px solid #f1cd75;

        .paper-view {
            border-radius: 5px;
            background: $white;
            max-height: 33vh;
            overflow-y: scroll;

            .form-control {
                border-radius: 0;

                &:focus {
                    box-shadow: none;
                    border: none;
                }

                &.betline-active {
                    background-color: #cacccd;
                }
            }
        }
    }

    .submit-btn {
        margin-top: 1rem;
        padding: 5px 15px 5px 15px;
    }
}

#bettingHistoryDetails {
    .order-string-container {
        border: 1px solid;
        padding: 15px;
        border-radius: 13px;

        p {
            font-family: $font-family-sfp-regular;
            color: #a6a6a6;
            font-size: 15px;
            margin-bottom: 0px;
        }
    }
}

.number-container {
    display: flex;
    align-items: center;
    flex: 2;

    input {
        margin: 0 0.2rem;
        height: $betInputHeight;
        border-radius: 8px;
        font-size: 1rem;
        padding: 0;
    }

    ::selection {
        background: transparent;
    }

    input:not(:last-child) {
        margin-right: 0.2rem;
    }
}

.number-type {
    flex: 1;

    .form-control {
        height: $betInputHeight;
    }
}

.bet-amount-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 0.5rem;

    .bet-group {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: 0.5rem;
    }

    .form-control {
        padding: 0.5rem;
        height: $betInputHeight;
        max-width: 75px;
    }

    label {
        color: #fff;
        margin-right: 0.5rem;
        margin-bottom: 0;
    }
}

.footer-amount-wrapper {
    padding: 0.8rem 1rem !important;
    display: flex;

    h2 {
        margin-bottom: 0;
        line-height: 1;
    }
}

.ticket-status {
    &.active {
        border: 1.7px solid #388e3c;
        color: #388e3c;
    }

    &.voided {
        border: 1.7px solid #de1607;
        color: #b33117;
    }

    border-radius: 27px;
    padding: 0.1rem 0.7rem;
    text-transform: uppercase;
    font-size: 0.8rem !important;
}

.multiselect-date {
    margin-bottom: 0.5rem;
}

.bet-notice {
    font-size: 90%;
}

.betlist-wrapper {
    background: rgba(0, 0, 0, 0.25);
    box-shadow: 0 8px 32px 0 black;
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);

    .betdates {
        padding: 0.4rem 0.6rem;
        margin-bottom: 0;
        font-size: 90%;
    }

    .table-betitems {
        max-width: 1000px;
        margin-bottom: 0.1rem;

        & td,
        & th {
            border: none;
            color: #fff;
            padding: 0.2rem 0.3rem;
            font-size: 0.8rem;
        }

        & td {
            font-family: "SFProDisplay-Regular";
        }

        & .fas {
            color: $red;
        }

        & td:last-child {
            white-space: nowrap;
        }
    }
}

.btn-confirm-bet {
    position: fixed;
    z-index: 2;
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
    width: 90vw;
    max-width: 500px;
    font-size: 1rem;
    font-family: "SFProDisplay-Regular";
    font-weight: 400;
}

#betSuccessful {
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 0;
    z-index: 2;
    background: url("../../assets/img/theme/ball-itself.png");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center center;
    background-attachment: fixed;

    .success-img-wrapper {
        width: 150px;
        height: 150px;

        img {
            width: 130px;
        }
    }

    .success-description {
        width: 100% !important;
        max-height: 50vh;
        margin-bottom: 1rem;
        display: block;
        overflow-y: scroll;
    }
}

.empty-state-bet {
    text-align: center;
    margin: 0.5rem;
}

.form-control {
    font-size: 0.75rem;

    &:focus {
        border: 2px solid $theme;
        box-shadow: 0px 0px 0px 0.1rem #e6ac0040;
    }
}

.pool-picker-wrapper {
    display: flex;

    .pool-selection {
        filter: grayscale(1);

        &.active {
            filter: unset;

            img {
                border: 1px solid;
            }
        }

        img {
            margin-right: 0.5rem;
            width: 30px !important;
            height: 30px !important;
            border-radius: 8px;
            background: #1a1a1a;
            padding: 0.1rem;
            object-fit: contain;
        }
    }
}

.btn-add-ticket {
    border-radius: 8px;
    padding: 0 1.1rem;
}

.bet-receipt {
    padding: 1rem;
    background: #16161617;
    box-shadow: 0 8px 32px 0 #1a1a1a;
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border-radius: 10px;
    font-family: "SFProDisplay-Regular";
    font-size: 0.8rem;
    line-height: 1.3;
    color: #fff;
    text-align: left;
}

#betSuccessful {
    .btn-share-wa {
        padding: 0.5rem 1rem;
        border-radius: 38px;
        width: max-content;
        display: inline-block;
        line-height: 1.8;
        align-items: center;
        font-size: 0.875rem;
    }
}

.bet-report-list {
    .bet-report-item {
        padding: 0.5rem;
        background: #16161617;
        box-shadow: 0 8px 32px 0 #1a1a1a;
        backdrop-filter: blur(4px);
        -webkit-backdrop-filter: blur(4px);
        border-radius: 10px;
        font-family: "SFProDisplay-Regular";
        font-size: 0.8rem;
        line-height: 1.3;
        color: #fff;
        text-align: left;
        margin-bottom: 0.3rem;
        border: 0.5px solid #212020;

        .bet-ticket-detail {
            margin-bottom: 0.5rem;
        }

        .bet-report-number {
            color: #f2d179;
        }

        .bet-detail {
            margin-right: 0.5rem;
        }
    }

    .table-bet-report-item {
        margin-bottom: 0;

        td,
        th {
            border: none;
            padding: 0 5px 0;
        }

        tr {
            td:first-child {
                padding-left: 0;
            }
        }
    }
}

.badge {
    &.badge-total-eat {
        font-size: 0.8rem !important;
        border-radius: 5px;
        margin-right: 0.3rem;
        font-family: "SFProDisplay-Regular";
        font-weight: 400;
        color: #141414;
        background: linear-gradient(180deg, #efbc62, #f2d179);
        box-shadow: #efbe6555 2px 2px 4px;
        margin-bottom: 0.3rem;
        display: inline-block;
    }
}

.form-control-transparent {
    background-color: transparent !important;
    padding: 0;
    line-height: 0 !important;
    height: 18px;
    border: none;
    font-size: 0.9rem;
}

.unselectable {
    user-select: none;
    -webkit-user-select: none;
}

.ul-date-selection {
    margin-left: 0;
    list-style: none;
    padding-left: 0;

    li {
        margin: 0.5rem 0;
        font-size: 0.9rem;
    }
}

.mode-selection {
    padding: 12px;
    border: 1px solid #f2d179;
    box-shadow: 0 8px 32px 0 #1a1a1a;
    border-radius: 50px;
    width: min-content;
    min-width: 150px;
    white-space: nowrap;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.2s all ease-in;

    &:hover {
        background: #f2d179;
        color: #1b1d26;
    }

    &:not(:last-child) {
        margin-right: 1rem;
    }
}

.credit-wrapper {
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    font-weight: bold;

    .badge-credit {
        padding: 0.2rem 0.2rem;
        margin-left: .3rem;
        background: #fff !important;
        display: inline-flex;
        max-height: 26px;

        img {
            width: 18px;
            margin-right: .2rem;
        }
    }
}