.sidebar {
    border-right: 1px solid rgba($light-gray, 0.6);

    .nav {
        padding: 0px;

        > li {
            &.nav-header {
                @include h-padding(15px);
                font-size: 14px;
                padding-bottom: 12px;
            }

            > a {
                border-radius: 0;
                padding: 12px 15px;
                color: $black;
            }

            &.active {
                > a {
                    background: $theme;
                    color: $white;
                }
            }
        }
    }

    .sub-menu {
        background-color: darken($white, 4.5%);

        > li > a:hover {
            color: $theme
        }

        > li.active > a {
            color: $theme;
        }
    }
}

.sidebar-mobile-dismiss {
    display: none;
}

.float-sub-menu-container {
    .float-sub-menu {
        @include box-shadow(0px 0px 5px 0px rgba($gray, 0.75));

        li > a:hover {
            color: $theme;
        }
    }
}

.page-sidebar-minified .sidebar .nav > li.active > a {
    color: $theme;
}