﻿.multiselect-date {
    --rmsc-radius: 8px;
    --rmsc-main: transparent;
    color: $primaryText !important;
    font-size: 0.75rem;
    .dropdown-container {
        height: 1.85rem;
        display: flex;
    }
}

.dropdown-content {
    z-index: 999;
    * {
        margin: 0 !important;
    }
    li div {
        height: 1rem;
        display: flex;
        align-items: center
    }
}