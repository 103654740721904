﻿.result-list-wrapper {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    text-align: center;
    grid-gap: 0rem 0.3rem;
    margin: 1rem 0 0;
}

.special-wrapper {
    & div:nth-last-child(3) {
        grid-area: 3 / 2 / 3 / 2;
    }
    & div:nth-last-child(2) {
        grid-area: 3 / 3 / 3 / 3;
    }
    & div:nth-last-child(1) {
        grid-area: 3 / 4 / 3 / 4;
    }
}