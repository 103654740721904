@font-face {
    font-family: Roboto;
    src: url(../../assets/fonts/RobotoRegular.ttf) format("truetype");
}

@font-face {
    font-family: RobotoBold;
    src: url(../../assets/fonts/RobotoBold.ttf) format("truetype");
    font-weight: bold;
}

@font-face {
    font-family: FontAwesome5Free;
    src: url(../../assets/css/webfonts/fa-solid-900.ttf) format("truetype");
}

@font-face {
    font-family: SFProDisplay-Regular;
    src: url(../../assets/fonts/SFProDisplay-Regular.ttf) format("truetype");
}

@font-face {
    font-family: SFProDisplay-Bold;
    src: url(../../assets/fonts/SFProDisplay-Bold.ttf) format("truetype");
}

@font-face {
    font-family: SFProDisplay-Medium;
    src: url(../../assets/fonts/SFProDisplay-Medium.ttf) format("truetype");
}