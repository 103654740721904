#homepage {
    .cr-balance {
        margin: 0.8rem 0 0.6rem 0;
        padding: 0.7rem;
        color: $black;
        background-image: url("../../assets/img/theme/cr-balance-bg.png"), linear-gradient(180deg, #efbc62, #f2d179);
        background-repeat: no-repeat;
        box-shadow: -2px -2px 4px #b1b1b160;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .tiny-text {
            display: block;
            font-size: 12px;
        }

        h3 {
            font-family: $font-family-sfp-bold;
            margin-top: auto;
            margin-bottom: auto;
            font-size: 18px;

            &.balance-val {
                text-align: right;
            }

            &.username-val {
                text-align: left;
            }
        }
    }

    #homepage-swiper {
        margin-bottom: 1rem;

        .swiper-content-wrapper {
            background: #16171b;
            border-radius: inherit;
            text-align: center;
            border-radius: $brand-border-radius;
            padding: .8rem 0;
            border: 1px solid #f1cd75;
            backdrop-filter: blur(4px);
            background: #141414a1;
            min-height: 88px;

            & .swiper-item-text {
                padding-top: 5px;
                line-height: 1.2;
                font-family: 'SFProDisplay-Medium';
                text-align: center;
                white-space: pre-line;
            }

            & .swiper-item-icon img {
                width: 25px;
                height: 25px;
                object-fit: contain;
                margin: 0 auto .2rem auto;
            }
        }
    }

    .place-order-item {
        height: 130px;
        border: 1px solid #f1cd75;
        border-radius: 13px;
        position: relative;
        overflow: hidden;
        background: #150816;

        .place-order-misc-item {
            position: absolute;
            padding: 15px;
            width: 100%;
            height: 100%;
            top: 0;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        .bet-action-title {
            color: #fff;
            text-shadow: 0 0 20px black;
            font-size: 23px;
        }

        .bet-action-desc {
            color: #fff;
            text-shadow: 0 0 20px black;
            letter-spacing: 0.1rem;
        }

        img {
            width: 100%;
            height: 100%;
            background: linear-gradient(131deg, #141414, #1a1c24);
            object-fit: cover;
            border-radius: inherit;
            display: flex;
        }
    }
}

body {
    font-family: $font-family-sfp-bold;
    min-width: 320px;
    // z-index: 0;
    // width: 100%;
    // height: 100%;
    // background: url("../assets/img/theme/ball-itself.png");
    // position: absolute;
    // background-repeat: no-repeat;
    // background-size: contain;
    // background-position: center;
    // background-attachment: fixed;
    .page-header-fixed {
        padding-top: 0px;
    }

    .page-without-sidebar .header .navbar-brand {
        justify-content: left;
    }

    .header {
        box-shadow: none;

        .dropdown.navbar-languager.dropdown a {
            padding: 10px;
            margin-left: -5px;
            display: flex;
            align-items: center;
            height: 34px;

            .las {
                font-size: 1.4rem;
            }
        }

        a {
            color: $body-font-color;

            &:hover {
                text-decoration: none;
            }
        }

        .dropdown-menu.show {
            background: $black;

            .dropdown-item {
                color: $body-font-color;

                &:hover {
                    background: #8b6e3d;
                }
            }
        }
    }

    .page-content-full-height .content {
        top: 0px;

        .set-page-content {
            position: relative;
            top: $navbar-height;
            margin-bottom: 30px;
        }
    }
}

.form-footer {
    width: 100%;
    margin-left: 0px;
    background-color: transparent;
    box-shadow: none;
    padding: 0px;
    // position: sticky;
    bottom: 1rem;

    .jumbotron {
        padding: 10px 20px;
        color: $black;
        background: linear-gradient(180deg, #efbc62, #f2d179);
        border-radius: 20px;
        margin-bottom: 0;
    }
}

.swiper-brand-icon-wrapper {
    img {
        filter: grayscale(1);
        background-color: $white;
        border-radius: 50%;
    }

    &.active {
        img {
            border-color: #f0c46b !important;
            filter: none;
        }
    }
}

#page-navigator,
#header {
    max-width: 500px;
    left: 50%;
    transform: translateX(-50%);
    top: 0px;
    background-color: transparent;
    color: $body-font-color;
    height: $navbar-height;
    position: absolute;
    z-index: 1050;
    width: 100%;

    .logo-text-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        line-height: 1;
        margin-bottom: 4px;

        .date-text {
            line-height: 0.85;
            color: #989898;
            font-size: 14px;
            font-family: $font-family-sfp-medium;
        }

        .logo-text {
            color: $body-font-color;
            font-size: 1rem;
            font-weight: bold;

            & {
                @media screen and (max-width: 350px) {
                    white-space: nowrap;
                    width: 80px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }
}

.dropdown.navbar-languager.dropdown {
    margin: 0px;

    a {
        border: 1px solid;
        border-radius: 13px;
    }

    &.show a {
        color: $body-font-color;
    }
}

/* general styles */

.dmc-logo-white,
.toto-logo-white {
    background-color: $white;
    border-radius: 50%;
}

.sfp-medium {
    font-family: $font-family-sfp-medium;
}

.sfp-bold {
    font-family: $font-family-sfp-bold;
}

.sfp-regular {
    font-family: $font-family-sfp-regular;
}

.cancel-btn {
    min-width: 100px;
    background: transparent;
    border-radius: 13px;
    border: 1px solid #d72626;
    color: #ed3131;
    padding: 2px 14px;

    &:active,
    &:focus,
    &:hover {
        border: 1px solid #be2424;
        color: #be2424;
        background: transparent !important;
    }
}

.theme-btn-3,
.page-back-btn {
    background: transparent;
    outline: none;
    border: none;
    color: #a6a6a6;
    padding: 0 .8rem;

    &:active,
    &:focus,
    &:hover {
        background: transparent;
    }

    .fa {
        font-size: 1.4rem;
        color: #fff;
    }
}

.general-text {
    font-family: $font-family-sfp-regular;
    font-size: 15px;
    color: #3e3e3e;
}

.d-grid {
    display: grid;
}

.react-datepicker-wrapper {
    input {
        border: none;
        background-color: transparent;
        font-weight: bold;
        width: 100%;

        &::-webkit-input-placeholder {
            /* Chrome/Opera/Safari */
            font-family: $font-family-sfp-medium;
            font-size: 16px;
            font-weight: bold;
        }

        &::-moz-placeholder {
            /* Firefox 19+ */
            font-family: $font-family-sfp-medium;
            font-size: 16px;
            font-weight: bold;
        }

        &:-ms-input-placeholder {
            /* IE 10+ */
            font-family: $font-family-sfp-medium;
            font-size: 16px;
            font-weight: bold;
        }

        &:-moz-placeholder {
            /* Firefox 18- */
            font-family: $font-family-sfp-medium;
            font-size: 16px;
            font-weight: bold;
        }
    }
}

.theme-text-2 {
    color: #d6d6d6;
}

.theme-text-3 {
    color: #a6a6a6;
}

.theme-text-4 {
    color: #989898;
}

.theme-btn-1 {
    border-radius: 38px;
    color: $black;
    background: linear-gradient(180deg, #efbc62, #f2d179);
    box-shadow: #efbe6555 2px 2px 4px;
    outline: none;
    border: none;
    font-family: $font-family-sfp-regular;
}

.theme-btn-4 {
    border-radius: 13px;
    background: linear-gradient(138deg, #141414, #1a1c24);
    border: 1px solid #f1cd75;
    box-shadow: -2px -2px 4px #1b1d26;
    color: $body-font-color;
}

.theme-btn-2 {
    border-radius: 38px;
    color: $body-font-color;
    background: linear-gradient(119deg, #141414, #1a1c24);
    border: 1px solid #1b1d26;
    box-shadow: 2px 2px 4px #0000008a;
    white-space: nowrap;
}

.theme-btn-5 {
    border-radius: 38px;
    padding: 7px 12px;
}

.page-nav-refresh-btn {
    border-radius: 13px;
    background: linear-gradient(138deg, #141414, #1a1c24);
    color: $body-font-color;
    border: 1px solid #f1cd75;
    padding: 0.1rem 0.6rem;
    font-size: 1.1rem;
}

.page-nav-info-btn {
    padding: 10px 15px 10px 15px;
    background: linear-gradient(138deg, #141414, #1a1c24);
    border: 1px solid #f1cd75;
    border-radius: 13px;

    .label {
        position: absolute;
        top: 15px;
        right: 10px;
        display: block;
        background: #f45;
        line-height: 12px;
        font-weight: 700;
        color: #fff;
        padding: 3px 6px;
        box-shadow: 0 2px 6px rgba(31, 34, 37, 0.5);
        border-radius: 40px;
    }
}

.gold-jumbotron {
    box-shadow: -2px -2px 4px #1b1d26;
    background: linear-gradient(180deg, #efbc62, #f2d179);
    border: 1px solid #f1cd75;
    border-radius: 20px;

    img.brand-icon {
        -khtml-user-select: none;
        -o-user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
        user-select: none;
    }
}


/* Number Meaning */

#numberMeaning {
    .search-btn {
        border: none;
        box-shadow: none;
        border-radius: 50px;
        position: absolute;
        right: 2%;
        top: 50%;
        transform: translateY(-50%);
    }

    .result-item {
        margin: auto;
        text-align: center;
    }
}


/* Strike History */

#strikeHistory {
    .history-item {
        border-radius: 13px;
        border: 1px solid;
        margin-bottom: 10px;
        background: #141414;
        box-shadow: 0 0 10px #000000;

        h3 {
            margin-top: auto;
            margin-bottom: auto;
        }

        img {
            width: 60px;
        }
    }
}


/* Bet Success Page */

.success-img-wrapper {
    div.success-icon-block {
        position: relative;
        width: 100%;
        height: 100%;

        img {
            position: absolute;
            top: 20%;
            left: 10%;
            width: 250px;
        }
    }

    margin: auto;
    background: #212122;
    width: 300px;
    height: 300px;
    display: block;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    transition: border 0.15s ease-in;
}


/* View Result Page */

#resultPage {
    .swiper-footer {
        padding: 10px 0px 10px 0px;
        border-top: 1px solid;
        border-bottom: 1px solid;
        position: fixed;
        bottom: 0;
        width: 100%;
        background: black;

        img {
            -webkit-user-select: none;
            /* Safari */
            -ms-user-select: none;
            /* IE 10+ and Edge */
            user-select: none;
            /* Standard syntax */
        }
    }

    .result-container {
        border-radius: inherit;
        background-color: #000000;
        border: 1px solid;
        border-radius: 13px;
        margin-bottom: 3rem;

        table {
            background-color: transparent;
            color: $white;

            thead {
                th {
                    border-color: #efbc62;
                }
            }

            .row-border {
                border-top: 1px solid #f1cd75;
                border-bottom: 1px solid #f1cd75;
            }

            tbody {
                font-size: 20px;
            }

            .gold-text {
                color: $body-font-color;
            }
        }

        .pool-name,
        .draw-date {
            line-height: 1;
            font-size: .8rem;
        }

        .pool-detail-wrapper {
            display: flex;
            align-items: center;
        }
    }

    .swiper-brand-icon-wrapper {
        img {
            filter: grayscale(1);
            border-radius: 4px;
            background-color: $white;
            width: 80px;
        }

        &.active {
            img.brand-icon {
                filter: grayscale(0);
                background-color: none;
            }
        }
    }
}


/* Pacakge List Page */

#packagesList {
    .package-tbl-wrapper {
        background: #141414;
        border: 1px solid #c4994f;
        border-radius: 13px;
        overflow: auto;
        margin-bottom: 10px;

        table {
            color: #efbc62;
            background-color: #3e3f48;

            thead {
                background-color: #292933;
            }

            tr.row-type {
                td {
                    color: $body-font-color !important;
                }
            }

            th,
            td {
                border: 1px solid #2e2e38;
                padding: 0px 0px 0px 3px;
                font-size: 90%;
            }

            tbody td:not(:first-child) {
                color: $white;
                font-family: $font-family-sfp-regular;
            }

            tbody td:first-child {
                width: 1%;
                white-space: nowrap;
                min-width: fit-content;
            }

            tr:nth-child(odd) {
                background-color: #292933;
            }
        }
    }
}

.place-order-item-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0.6rem;
    margin-bottom: 1rem
}

.table-wallet-statements {
    background: #16161617;
    box-shadow: 0 8px 32px 0 #1a1a1a;
    backdrop-filter: blur( 4px );
    -webkit-backdrop-filter: blur( 4px );
    border-radius: 10px;
    margin-top: 1rem;

    td:first-child {
        white-space: break-spaces;
    }

    td, th {
        font-size: .7rem;
        color: #fff !important;
        line-height: 1;
        padding: 3px;
        border-color: #262626;
    }

    td {
        font-family: $font-family-sfp-regular;
    }

    td.text-red {
        color: $red !important;
    }

    td.text-green {
        color: $green !important;
    }
}

.flex-container {
    min-height: 90vh;
    display: flex;
    flex-direction: column;

    .tab-content-help {
        flex: 1;
        display: flex;
        flex-direction: column;

        .tab-pane-help {
            height: 0;
            overflow-y: hidden;
        }

        .tab-pane-help.active-tab {
            display: flex;
            flex: 1;
            height: 100%;
            flex-direction: column;
        }
    }
}

.brand-nav {
    .nav-link {
        &:hover {
            color: #f0bf67 !important;
        }

        &.active {
            border-bottom: 2px solid #efbc62;
            color: #efbc62 !important;
            background: none !important;
            cursor: pointer;
        }
    }
}

.jackpot-history-item {
    line-height: 1;

    .badge-total-eat {
        margin-bottom: 0 !important;
    }

    .header-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: .8rem;

        .title-label {
            font-size: 100%;
        }
    }

    .title-label {
        font-weight: 400;
        font-family: 'SFProDisplay-Regular';
        font-size: 85%;
        text-transform: uppercase;
    }

    .prize-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .title-label {
            margin-top: .1rem;
        }

        div {
            display: flex;
            text-align: center;
            flex-direction: column;
            flex: 1;
        }

        div:nth-child(2) {
            flex: 2;
        }

        div:first-child, div:last-child {
            max-width: fit-content;
            flex: 1;
        }
    }
}

.player-wrapper {
    text-align: center;
}
