﻿.sweet-alert {
    background-color: $body-bg-color !important;
    box-shadow: 0 0 10px 7px #0000003d;

    h2 {
        font-size: 1rem !important;
        color: #fff;
    }

    & > div {
        zoom: .7
    }

    & > div > div:first-child,
    & > div > div:nth-last-child(2),
    & > div > div:last-child {
        background: unset !important;
    }
}
